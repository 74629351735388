import React, { useEffect, useRef, useState } from "react";
import Texteditor from "./texteditor/Texteditor";
import "react-quill/dist/quill.snow.css";
import { removeTag } from "../../helpers";

const HideableTexteditor = () => {
  const quillRef = useRef(null);
  const [showEditor, setShowEditor] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (removeTag(value)) {
      setShowEditor(false);
    } else {
      setShowEditor(true);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (quillRef.current && !quillRef.current.contains(event.target)) {
        if (removeTag(value)) {
          setShowEditor(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [value]);

  const handleEditorClick = () => {
    if (!showEditor) {
      setShowEditor(true);
    }
  };

  return (
    <div ref={quillRef}>
      {!showEditor ? (
        <div className="ql-snow" onClick={() => handleEditorClick()}>
          <div
            className="ql-editor !px-0"
            dangerouslySetInnerHTML={{ __html: value }}
          ></div>
        </div>
      ) : (
        <Texteditor value={value} onChange={setValue} />
      )}
    </div>
  );
};

export default HideableTexteditor;
