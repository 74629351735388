import React from 'react';
import {setTitle} from '../helpers/MetaTag';
import Button from '../components/form/Button';
import Input from '../components/form/Input';

const Login = () => {
  setTitle('Journal 365 | Login');
  return (
    <>
      <div className="w-full h-screen flex justify-center items-center bg-slate-100">
        <div className="w-full max-w-sm bg-white rounded shadow-md py-8 px-6">
          <div className="divide-y divide-slate-200 space-y-4">
            <div className="relative">
              <h1 className="text-indigo-900 font-bold text-center text-3xl capitalize">Login</h1>
              <div className="text-sm text-indigo-900 text-center font-medium">Please use your credentials to login</div>
            </div>
            <form className="pt-4 space-y-4">
              <div className="relative">
                <Input 
                  label={'Email'} 
                  labelClasses={'!text-xs'}
                  inputType={'email'} 
                  inputPlaceholder={'Email address'} 
                  errorType={''}
                />
              </div>
              <div className="relative">
                <Input 
                  label={'Password'} 
                  labelClasses={'!text-xs'}
                  inputType={'password'} 
                  inputPlaceholder={'Your Password'} 
                  errorType={''}
                />
              </div>
           
              <div className="relative flex justify-center">
                <Button 
                  buttonClasses={''}
                  buttonType={'submit'}
                  buttonIcon={'fa-light fa-arrow-right-to-arc'}
                  buttonIconPosition={'left'}
                  buttonLabel={'Login'}
                  buttonLabelClasses={''}
                  buttonFunction={''}
                  buttonHasLink={true}
                  buttonLink={'/dashboard'}
                />
              </div>
            </form>
          </div>
            
        </div>
      </div>
    </>
  );
};

export default Login;