import React, { useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import MonthlyGoalSection from "../components/sections/MonthlyGoalSection";
import CalenderLayout from "../components/sections/CalenderLayout";
setTitle("Journal 365 | Monthly Goal");

const MonthlyGoal = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(
    currentYear ? currentYear : ""
  );

  return (
    <>
      <CalenderLayout
        currentYear={currentYear}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        setCurrentYear={setCurrentYear}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}
      >
        <div className="p-5 bg-white rounded-md">
          <MonthlyGoalSection
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            currentYear={currentYear}
          />
        </div>
      </CalenderLayout>
    </>
  );
};

export default MonthlyGoal;
