import React, { useEffect, useState, useRef } from "react";
import { classNames } from "../../helpers/classNames";

const Input = ({
  label,
  labelClasses,
  inputType,
  inputClasses,
  inputPlaceholder,
  inputValue,
  value,
  inputName,
  errorType,
  errorText,
  isDisabled,
  onBlur,
  onFocus,
  onChange,
  isInputGroup = false,
  inputGroupIcon,
  inputGroupPosition,
  inputGroupIconClass,
  ...props
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const passwordInput = useRef(null);
  const handleChange = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  useEffect(() => {
    passwordInput.current.type = passwordVisibility ? "text" : inputType;
  }, [passwordVisibility]);

  return (
    <>
      {label && (
        <div
          className={"text-sm font-medium text-slate-500 mb-1 " + labelClasses}
        >
          {label}
        </div>
      )}
      <div
        className={classNames(
          "relative flex w-full",
          isDisabled ? "opacity-50" : "opacity-100"
        )}
      >
        {isInputGroup && inputGroupPosition === "left" && (
          <div
            className={classNames(
              "w-10 min-w-[40px] flex items-center justify-center border border-slate-200 rounded-xl rounded-r-none bg-white text-royal-blue-500 text-base",
              inputGroupIconClass
            )}
          >
            <i className={classNames("fa-fw", inputGroupIcon)}></i>
          </div>
        )}
        <div className="relative flex w-full">
          {isDisabled ? (
            <input
              type={inputType}
              ref={passwordInput}
              className={
                "w-full h-10  bg-slate-50 border border-slate-200 px-3 text-sm text-indigo-900 !ring-0 !outline-0 focus:border-indigo-500 transition-all duration-200 " +
                inputClasses
              }
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={inputPlaceholder}
              name={inputName}
              value={value}
              defaultValue={inputValue}
              autoComplete={"new-" + inputType}
              disabled
            />
          ) : (
            <input
              type={inputType}
              ref={passwordInput}
              className={
                "w-full h-10  bg-slate-50 border border-slate-200 px-3 text-sm text-indigo-900 !ring-0 !outline-0 focus:border-indigo-500 transition-all duration-200 " +
                inputClasses
              }
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              value={value}
              placeholder={inputPlaceholder}
              name={inputName}
              defaultValue={inputValue}
              autoComplete={"new-" + inputType}
            />
          )}
          {inputType === "password" && (
            <button
              type="button"
              className="w-10 h-10 absolute top-0 right-0 bg-transparent text-indigo-900"
              onClick={handleChange}
            >
              <i
                className={
                  "fa-regular fa-fw " +
                  (passwordVisibility ? "fa-eye-slash" : "fa-eye")
                }
              ></i>
            </button>
          )}
        </div>
        {isInputGroup && inputGroupPosition === "right" && (
          <div className="w-10 min-w-[40px] flex items-center justify-center border border-slate-200 rounded-md rounded-l-none bg-slate-50 text-sm text-slate-500">
            <i className={classNames("fa-fw", inputGroupIcon)}></i>
          </div>
        )}
      </div>

      {errorType && errorType === "error" && (
        <div className="text-xs text-red-600 mt-1">
          <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "warning" && (
        <div className="text-xs text-indigo-600 mt-1">
          <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "success" && (
        <div className="text-xs text-green-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "info" && (
        <div className="text-xs text-sky-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
          {errorText}
        </div>
      )}
    </>
  );
};

export default Input;
