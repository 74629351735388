import React from "react";
import TopHeaderMenuItem from "./TopHeaderMenuItem";

const TopMenuBlock = ({ menuTitle, menuData, isMenuOpen, ...props }) => {

  return (
    <>
      <div className="relative py-4 px-4 space-y-2 transition-all duration-100">
        <div className="gap-5 -mx-1 flex">
          {menuData.map((item) => (
            <TopHeaderMenuItem
              key={item._id}
              menuLink={item.menuLink}
              menuIconColor={item.menuIconColor}
              menuLabel={item.menuLabel}
              highlight={item?.highlight}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default TopMenuBlock;
