import React, { useEffect, useState } from "react";
import Input from "../../form/Input";
import Button from "../../form/Button";

const AddTask = ({
  cancel = () => {},
  saveHandler = () => {},
  editData = {},
  title = "Add Task",
}) => {
  const [taskName, setTaskName] = useState("");

  useEffect(() => {
    setTaskName(editData?.label);
  }, [editData]);

  return (
    <div className="p-4">
      <div className="text-indigo-900 text-xl font-semibold text-center border-b border-indigo-900 pb-3">
        {title}
      </div>
      <div className="my-5">
        <Input
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          inputClasses={"rounded"}
          inputPlaceholder={"Enter task name"}
        />
      </div>
      <div className="flex justify-end mt-3 gap-2">
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close"}
          buttonLabel={"Cancel"}
          buttonFunction={cancel}
        />
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-save"}
          buttonLabel={"Save task"}
          buttonFunction={() => saveHandler(taskName)}
        />
      </div>
    </div>
  );
};

export default AddTask;
