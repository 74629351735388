import React from "react";
import NotesAction from "./NotesAction";
import NoteInput from "./NoteInput";

const NotesItem = ({ index = 0, data = {}, onChange = () => {} }) => {
  return (
    <div
      className={`bg-white p-2 pl-7 pr-7 rounded-md shadow hover:shadow-lg relative transition-all cursor-pointer group`}
    >
      <span className="bg-indigo-900 text-white flex justify-center items-center h-6 w-6 absolute -top-1 -left-1 text-xs rounded-[3px]">
        {index + 1}
      </span>
      <div className="text-indigo-900 justify-center items-center h-6 w-6 absolute top-1 right-1 text-xs rounded-[3px] hidden group-hover:flex ">
        <NotesAction data={data} editAction={(data) => console.log(data)} />
      </div>
      <NoteInput value={data?.value} onChange={(e)=>onChange(e)}/>
    </div>
  );
};

export default NotesItem;
