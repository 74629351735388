import React, { useState } from "react";
import Button from "../../form/Button";
import Textarea from "../../form/Textarea";
import Modal from "../../elements/Modal";
import AddTask from "./AddTask";

const RenderdayByMonths = ({
  calender = {},
  monthIndex = 1,
  selectedYear = new Date().getFullYear(),
  note = [],
  setNoteAndTask = () => {},
  getCurrentDate = () => {},
}) => {
  const [ModalOpen, setModalIsOpen] = useState({
    isOpen: false,
    target: null,
    editData: null,
  });

  const onChangeNote = (data) => {
    setNoteAndTask((pre) => {
      pre[data?.index].note = data?.value;
      return [...pre];
    });
  };

  const onTaskChange = (data) => {
    setNoteAndTask((pre) => {
      const newArray = [...pre];
      newArray[data?.index].tasks[data?.taskIndex].checked = data?.value;
      return newArray;
    });
  };

  const editTask = (data) => {
    setModalIsOpen({
      isOpen: true,
      target: data?.target,
      editData: data?.task,
    });
  };

  const addTask = (data) => {
    setModalIsOpen({ isOpen: true, target: data, editData: null });
  };

  const onSave = (data) => {
    if (ModalOpen?.editData) {
      setNoteAndTask((pre) => {
        const targetTask =
          pre[ModalOpen?.target?.index].tasks[ModalOpen?.editData?.taskIndex];
        pre[ModalOpen?.target?.index].tasks[ModalOpen?.editData?.taskIndex] = {
          ...targetTask,
          label: data,
        };
        return [...pre];
      });
    } else {
      setNoteAndTask((pre) => {
        pre[ModalOpen?.target?.index].tasks = [
          ...(pre[ModalOpen?.target?.index].tasks || []),
          { label: data, checked: false },
        ];
        return [...pre];
      });
    }
    setModalIsOpen({
      isOpen: false,
      target: null,
      editData: null,
    });
  };

  return (
    <>
      <div className="w-full">
        <div className="flex gap-2 mb-3">
          <div className="w-32 flex-grow-0 bg-indigo-900 text-white py-1 text-center rounded">
            Days
          </div>
          <div className="w-[calc(100%-128px)] flex gap-2">
            <div className="w-1/2 bg-indigo-900 text-white py-1 text-center rounded">
              Daily Highlights
            </div>
            <div className="w-1/2 bg-indigo-900 text-white py-1 text-center rounded">
              Daily Goal
            </div>
          </div>
        </div>
        {note &&
          Array.isArray(note) &&
          note.map(({ day, note, tasks }, i) => (
            <div key={i?.toString()} className="flex gap-2 mb-2 group">
              <div
                className={`${
                  calender?.currentMonth == monthIndex && calender?.today == day
                    ? "!bg-indigo-900 [&>*]:!text-white"
                    : ""
                } w-32 flex-grow-0 space-y-0.5 rounded py-2 group-odd:bg-indigo-100 group-even:bg-indigo-50 day ${
                  getCurrentDate(monthIndex, day) === "Saturday" ||
                  getCurrentDate(monthIndex, day) === "Sunday"
                    ? "weekend"
                    : ""
                }`}
              >
                <div className="date text-lg text-center text-indigo-900 font-bold">
                  {day}
                </div>
                <div className="day-label text-sm text-center text-indigo-900 font-bold tracking-wider">
                  {getCurrentDate(monthIndex, day)}
                </div>
              </div>
              <div className="w-[calc(100%-128px)] flex gap-2">
                <div className="w-1/2 rounded">
                  <div
                    className={`flex-grow h-full space-y-0.5 rounded py-2 group-odd:bg-indigo-100 group-even:bg-indigo-50 day ${
                      getCurrentDate(monthIndex, day) === "Saturday" ||
                      getCurrentDate(monthIndex, day) === "Sunday"
                        ? "weekend"
                        : ""
                    }`}
                  >
                    <div className="px-3">
                      <Textarea
                        inputPlaceholder={"Add note"}
                        inputClasses="h-12 !border-0 group-odd:bg-indigo-100 group-even:bg-indigo-50 pr-10"
                        onChange={(e) =>
                          onChangeNote({
                            value: e.target?.value,
                            day,
                            index: i,
                          })
                        }
                        value={note}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-1/2 rounded flex gap-1 overflow-hidden">
                  <div className="flex gap-1 h-full w-[calc(100%-80px)] overflow-x-auto scrollbar ">
                    {tasks &&
                      Array?.isArray(tasks) &&
                      tasks?.length > 0 &&
                      tasks?.map((task, index) => (
                        <div
                          key={index?.toString()}
                          className={`relative max-w-[7rem]`}
                        >
                          <div
                            onClick={() =>
                              editTask({
                                target: { day, note, tasks, index: i },
                                task: { taskIndex: index, ...task },
                              })
                            }
                            className="bg-indigo-500/90 text-white cursor-pointer overflow-hidden whitespace-nowrap px-1 rounded text-sm max-w-[112px] min-w-[112px] text-center truncate py-[2px]"
                          >
                            {task?.label}
                          </div>
                          <button
                            onClick={() =>
                              onTaskChange({
                                index: i,
                                day,
                                taskIndex: index,
                                value: !task?.checked,
                              })
                            }
                            type="button"
                            className="mt-1 w-full cursor-pointer group-odd:bg-indigo-100 group-even:bg-indigo-50  h-[calc(100%-30px)] rounded flex flex-col justify-center items-center"
                          >
                            {task?.checked && (
                              <i className="fa-solid fa-check text-green-600 text-lg"></i>
                            )}
                          </button>
                        </div>
                      ))}
                  </div>
                  <div className="bg-indigo-50 rounded w-20 h-full flex items-center justify-center">
                    <Button
                      buttonClasses={"h-8 w-8"}
                      buttonIcon={"fa-regular fa-plus text-sm"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonFunction={() =>
                        addTask({ day, note, tasks, index: i })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <Modal
        isOpen={ModalOpen?.isOpen}
        setIsOpen={() =>
          setModalIsOpen({ isOpen: false, target: null, editData: null })
        }
        size="md"
      >
        <AddTask
          cancel={() =>
            setModalIsOpen({ isOpen: false, target: null, editData: null })
          }
          saveHandler={(data) => onSave(data)}
          title={ModalOpen?.editData ? "Edit task" : "Add Task"}
          editData={ModalOpen?.editData}
        />
      </Modal>
    </>
  );
};

export default RenderdayByMonths;
