import React, { useEffect, useRef, useState } from "react";
import Textarea from "../../form/Textarea";

const NoteInput = ({ value = "", onChange = () => {} }) => {
  const editorRef = useRef(null);
  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    if (value) {
      setShowEditor(false);
    } else {
      setShowEditor(true);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        if (value) {
          setShowEditor(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [value]);

  const handleEditorClick = () => {
    if (!showEditor) {
      setShowEditor(true);
    }
  };

  return (
    <div ref={editorRef} className="w-full">
      {!showEditor ? (
        <div onClick={() => handleEditorClick()}>{value}</div>
      ) : (
        <Textarea
          autoFocus={value ? true : false}
          inputPlaceholder={!value ? "Add notes" : ""}
          inputValue={value}
          inputClasses="h-12 !border-none bg-transparent pr-10"
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </div>
  );
};

export default NoteInput;
