import React, { useEffect, useRef, useState } from "react";
import Nofile from "./Nofile";
import Preview from "./Preview";

const DraggableFileupload = ({
  max,
  multiple = false,
  onChange = () => {},
  values,
  accept, //if multiple = false; the accept = "image/png" || accept = ["image/png"]
}) => {
  const imgRef = useRef(null)
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState(null);
  const [errorText, setErrortext] = useState("");

  useEffect(() => {
    if (values || values?.length > 0) {
      setFiles(values);
    }
  }, [values]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    setErrortext("");
    e.preventDefault();
    if (!multiple) {
      setDragging(false);
      const droppedFiles = e.dataTransfer.files[0];
      if (accept) {
        if (accept === droppedFiles?.type) {
          onChange(droppedFiles);
          setFiles(droppedFiles);
          return;
        } else {
          if (droppedFiles) {
            setErrortext(
              `This type file is not acceptable, Only accept ${
                accept?.split("/")[1]
              } file`
            );
          } else {
            setErrortext("");
          }
          return;
        }
      } else {
        onChange(droppedFiles);
        setFiles(droppedFiles);
      }
    } else {
      setDragging(false);
      const droppedFiles = Array.from(e.dataTransfer.files);

      if (accept && Array.isArray(accept) && accept?.length > 0) {
        let dropped_files = [];
        let isError = false;
        let error = [];
        droppedFiles?.forEach((item) => {
          if (accept.includes(item?.type)) {
            dropped_files.push(item);
            return;
          } else {
            isError = true;
          }
        });
        if (isError) {
          accept?.forEach((item) => {
            error.push(item?.split("/")[1]);
          });

          if (droppedFiles && droppedFiles?.length > 0) {
            setErrortext(
              `These type files are not acceptable, Only accept ${error.join(
                ", "
              )} files`
            );
          } else {
            setErrortext("");
          }
          isError = false;
        }
        onChange(max ? dropped_files?.slice(0, max) : dropped_files);
        setFiles(max ? dropped_files?.slice(0, max) : dropped_files);
      } else {
        onChange(max ? droppedFiles?.slice(0, max) : droppedFiles);
        setFiles(max ? droppedFiles?.slice(0, max) : droppedFiles);
      }
    }
    imgRef.current.value = null
  };

  const handleFileInputChange = (e) => {
    setErrortext("");
    if (!multiple) {
      const selectedFiles = e.target.files[0];
      if (accept) {
        if (accept === selectedFiles?.type) {
          onChange(selectedFiles);
          setFiles(selectedFiles);
          return;
        } else {
          if (selectedFiles) {
            setErrortext(
              `This type file is not acceptable, Only accept ${
                accept?.split("/")[1]
              } file`
            );
          } else {
            setErrortext("");
          }
          return;
        }
      } else {
        onChange(selectedFiles);
        setFiles(selectedFiles);
        return;
      }
    } else {
      const selectedFiles = Array.from(e.target.files);
      if (accept && Array.isArray(accept) && accept?.length > 0) {
        let select_files = [];
        let isError = false;
        let error = [];
        selectedFiles?.forEach((item) => {
          if (accept.includes(item?.type)) {
            select_files.push(item);
            return;
          } else {
            isError = true;
          }
        });
        if (isError) {
          accept?.forEach((item) => {
            error.push(item?.split("/")[1]);
          });
          if (selectedFiles && selectedFiles?.length > 0) {
            setErrortext(
              `These type files are not acceptable, Only accept ${error.join(
                ", "
              )} files`
            );
          } else {
            setErrortext("");
          }
          isError = false;
        }
        onChange(max ? select_files.slice(0, max) : select_files);
        setFiles(max ? select_files.slice(0, max) : select_files);
      } else {
        onChange(max ? selectedFiles.slice(0, max) : selectedFiles);
        setFiles(max ? selectedFiles.slice(0, max) : selectedFiles);
      }
    }
    imgRef.current.value = null
  };

  function removeFile({ data, index, multiple }) {
    if (multiple) {
      setFiles(files?.filter((_, i) => i !== index));
      onChange(files?.filter((_, i) => i !== index));
    } else {
      setFiles(null);
      onChange(null);
    }
  }

  return (
    <div className="flex gap-2 items-center">
      <div
        className={`dropzone relative w-32 h-32 overflow-hidden ${
          dragging ? "dragging" : ""
        } rounded-md border-2 border-slate-400 border-dashed`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          ref={imgRef}
          type="file"
          id="fileInput"
          onChange={handleFileInputChange}
          multiple={multiple}
          style={{ display: "none" }}
        />
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <Nofile error={errorText} />
        </div>
        <label
          htmlFor="fileInput"
          className="absolute top-0 left-0 opacity-0 w-full h-full flex"
        ></label>
      </div>
      <Preview data={files} multiple={multiple} removeFile={removeFile} />
    </div>
  );
};

export default DraggableFileupload;
