import React from "react";
import Image from "../../elements/Image";

const Preview = ({ data, multiple, removeFile = () => {} }) => {
  return (
    <>
      {(data || (Array.isArray(data) && data?.length > 0)) && (
        <div className="flex flex-wrap gap-2 items-center my-5">
          {multiple ? (
            data?.map((file, i) => (
              <div className="relative w-32 h-32" key={i?.toString()}>
                <div className="w-full h-full overflow-hidden border border-slate-200 bg-white rounded-md">
                  <Image
                    src={
                      file instanceof File ? URL.createObjectURL(file) : file
                    }
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-cover"}
                  />
                </div>
                <button
                  onClick={() => removeFile({ data, index: i, multiple })}
                  type="button"
                  className="w-5 h-5 rounded-full bg-white shadow absolute -top-2.5 -right-2.5 text-red-500 text-sm hover:bg-red-500 hover:text-white transition-all"
                >
                  <i className="fa-regular fa-fw fa-xmark"></i>
                </button>
              </div>
            ))
          ) : (
            <div className="relative w-28 h-28">
              <div className="w-full h-full overflow-hidden border border-slate-200 bg-white rounded-md">
                <Image
                  src={data instanceof File ? URL.createObjectURL(data) : data}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
              <button
                onClick={() => removeFile({ data, multiple })}
                type="button"
                className="w-5 h-5 rounded-full bg-white shadow absolute -top-2.5 -right-2.5 text-red-500 text-sm hover:bg-red-500 hover:text-white transition-all"
              >
                <i className="fa-regular fa-fw fa-xmark"></i>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Preview;
