import React, { useState } from "react";
import Button from "../../form/Button";
import NotesItem from "./NotesItem";

const Note = ({ title = "" }) => {
  const [content, setContent] = useState([
    {
      index: 0,
      value: "Blog Card is a component that displays.",
    },
    {
      index: 1,
      value: "Blog Card is a component that displays.",
    },
    {
      index: 2,
      value: "",
    },
  ]);


  return (
    <div className="border border-indigo-900 rounded-md overflow-hidden shadow-md">
      <div className="h-9 text-center flex items-center justify-center bg-indigo-900 text-white font-semibold">
        {title}
      </div>
      <div className="min-h-[100px] p-3 px-4 flex flex-col gap-y-3">
        {content &&
          Array.isArray(content) &&
          content?.length > 0 &&
          content?.map((item, i) => (
            <NotesItem
              key={i?.toString()}
              index={i}
              data={item}
              onChange={(value) =>
                setContent(
                  content?.map((elm, index) =>
                    i === index ? { ...elm, value } : elm
                  )
                )
              }
            />
          ))}
      </div>
      <div className="p-2">
        <Button
          buttonFunction={() =>
            setContent((pre) => [
              ...pre,
              { index: crypto.randomUUID(), value: "" },
            ])
          }
          buttonLabel={"Add Notes"}
          buttonClasses={"w-full h-8"}
        />
      </div>
    </div>
  );
};

export default Note;
