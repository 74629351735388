import React from "react";
import Note from "./monthlyGoalSection/Note";
import DailyTraking from "./monthlyGoalSection/DailyTraking";
import { months } from "../../constant";

const MonthlyGoalSection = ({ currentYear, selectedMonth, selectedYear }) => {
  return (
    <div className="calendar w-full space-y-5">
      <div className="flex items-center justify-center">
        <div className="text-indigo-900 font-bold text-center text-3xl capitalize">
          {months[selectedMonth]} {selectedYear} Monthly Goals
        </div>
      </div>
      <div className="flex gap-3 w-full">
        <div className="w-full flex-grow-1">
          <div className="h-10 bg-indigo-900 text-white flex items-center justify-center mb-5 rounded">
            Daily Tracking
          </div>
          <div className="max-h-[520px] scrollbar overflow-y-auto">
            <DailyTraking
              monthIndex={selectedMonth}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
            />
          </div>
        </div>
        <div className="flex-grow-0 w-[1000px] flex flex-col gap-y-5">
          <Note title="Monthly Goal" />
          <Note title="Events" />
          <Note title="Birthday" />
        </div>
      </div>
    </div>
  );
};

export default MonthlyGoalSection;
