import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import DefaultSidebar from '../shared/DefaultSidebar';
import DefaultHeader from '../shared/DefaultHeader';

const DefaultLayout = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <div className="relative flex w-full h-screen overflow-hidden">
        {/* <div className={"fixed h-screen flex transition-all duration-100 z-10 " + (isMenuOpen?'w-16':'w-60')}>
          <DefaultSidebar isMenuOpen={isMenuOpen}/>
        </div> */}
        <div className={"relative w-full flex flex-col transition-all duration-100 "}>
          <DefaultHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
          <div className="relative w-full h-full max-h-[calc(100vh-4rem)] p-6 overflow-auto scroll-smooth scrollbar bg-slate-100">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;