import React, { useEffect, useState } from "react";
import Modal from "../elements/Modal";
import RenderdayByMonths from "./dailyGoals/RenderdayByMonths";
import { months } from "../../constant";
import AddTask from "./dailyGoals/AddTask";

const daysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const DailyGoalSection = ({ selectedMonth, selectedYear }) => {
  const [noteAndTask, setNoteAndTask] = useState([]);
  const daysCount = daysInMonth(selectedYear, selectedMonth);
  const calender = {
    today: new Date().getDay(),
    currentMonth: new Date().getMonth(),
  };

  useEffect(() => {
    setNoteAndTask(
      Array.from({ length: daysCount }, (_, i) => i + 1)?.map((day, i) => ({
        day,
        note: "",
        tasks: [
          { label: "task one", checked: false },
          { label: "task two", checked: false },
          { label: "task three", checked: false },
          { label: "task four", checked: false },
        ],
      }))
    );
  }, [daysCount, selectedMonth]);

  const getCurrentDate = (monthIndex, day) => {
    const year = selectedYear;
    const currentMonth = monthIndex;
    const currentDate = new Date(year, currentMonth, day).getDay();

    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][currentDate];
  };

  return (
    <>
      <div className="calendar w-full space-y-2">
        <div className="flex items-center justify-center">
          <div className="text-indigo-900 font-bold text-center text-3xl capitalize">
            {months[selectedMonth]} {selectedYear}
            {/* Daily Highlights */}
          </div>
        </div>
        <div className="w-full">
          <RenderdayByMonths
            getCurrentDate={getCurrentDate}
            monthIndex={selectedMonth}
            selectedYear={selectedYear}
            note={noteAndTask}
            calender={calender}
            setNoteAndTask={setNoteAndTask}
          />
        </div>
      </div>
    </>
  );
};

export default DailyGoalSection;
