import React, { useMemo, useState } from "react";
import Input from "../../form/Input";
import Button from "../../form/Button";
import { getOrdinalSuffix } from "../../../helpers";

const DailyTraking = ({ monthIndex, selectedYear, selectedMonth }) => {
  const getCurrentDate = (monthIndex, day) => {
    const year = selectedYear;
    const currentMonth = monthIndex;
    const currentDate = new Date(year, currentMonth, day).getDay();
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][currentDate];
  };
  const [days, setdays] = useState([]);

  const mon = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const addNote = ({ day, index }) => {
    setdays(
      days?.map((elm, i) =>
        elm?.day === day
          ? {
              ...elm,
              notes: [
                ...elm?.notes,
                { id: crypto.randomUUID(), value: "", icon: "", tasks: [] },
              ],
            }
          : elm
      )
    );
  };

  const removeNote = ({ day, note_index }) => {
    setdays(
      days?.map((elm, i) =>
        elm?.day === day
          ? {
              ...elm,
              notes: elm?.notes?.filter((_, i) => i !== note_index),
            }
          : elm
      )
    );
  };

  const onChangeNoteValue = ({ value, day, note_index }) => {
    setdays(
      days?.map((elm, i) =>
        elm?.day === day
          ? {
              ...elm,
              notes: elm?.notes?.map((note, index) =>
                index === note_index ? { ...note, value } : note
              ),
            }
          : elm
      )
    );
  };

  const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysCount = daysInMonth(selectedYear, monthIndex);

  useMemo(() => {
    setdays(
      Array.from({ length: daysCount }, (_, i) => i + 1)?.map((day, i) => ({
        day,
        notes: [{ id: 1, value: "", icon: "", tasks: [] }],
      }))
    );
  }, [daysCount]);

  return days.map(({ day, notes }, index) => {
    return (
      <div key={day} className="group bg-indigo-100 px-2 rounded-lg py-2 mb-3">
        <div
          className={`flex items-center space-x-1 mb-3 space-y-0.5 day  ${
            getCurrentDate(monthIndex, day) === "Saturday" ||
            getCurrentDate(monthIndex, day) === "Sunday"
              ? "weekend"
              : ""
          }`}
        >
          <div className="date text-lg  text-indigo-900 font-bold">{getOrdinalSuffix(day)}</div>
          <div className="date text-lg  text-indigo-900 font-bold">
            {mon[selectedMonth]},
          </div>
          <div className="day-label text-lg text-indigo-900 font-bold tracking-wider">
            {getCurrentDate(monthIndex, day)}
          </div>
          <Button
            buttonLabel={"add"}
            buttonIconPosition={"left"}
            buttonIcon={"fa-light fa-plus"}
            buttonLabelClasses={"capitalize"}
            buttonClasses={"!ml-auto"}
            buttonFunction={() => addNote({ day, index })}
          />
        </div>
        <div className="relative space-y-2 mt-2 w-full">
          {notes.map((note, i) => (
            <div className="flex relative gap-x-3" key={note.id}>
              <div className="w-full relative">
                <Input
                  inputPlaceholder="Add note"
                  inputValue={note.value}
                  onChange={(e) =>
                    onChangeNoteValue({
                      value: e.target.value,
                      day,
                      note_index: i,
                    })
                  }
                  inputClasses="h-10 !border-none  pr-10 !rounded-lg"
                />
              </div>
              <div className="flex gap-x-2 items-center">
                <Button
                  buttonIconPosition={"left"}
                  buttonIcon={"fa-light fa-save"}
                  buttonClasses={"h-10"}
                  buttonLabel={""}
                />
                {notes?.length > 1 && (
                  <Button
                    buttonIconPosition={"left"}
                    buttonIcon={"fa-light fa-trash-can"}
                    buttonClasses={"h-10 bg-red-500"}
                    buttonLabel={""}
                    buttonFunction={() => removeNote({ day, note_index: i })}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  });
};

export default DailyTraking;
