import React from "react";
import uploadFileImg from "../../../assets/images/icon/uploadFile.svg";

const Nofile = ({ error = "" }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-10 h-10">
        <img
          src={uploadFileImg}
          alt="upload-icon"
          className="w-full h-full object-contain object-center"
        />
      </div>
      <div>
        <div className="text-center text-slate-600 text-sm font-semibold">
          Upload Picture
        </div>
        <div className="text-center text-slate-600 text-[12px]">
          Drag and drop, or click to select
        </div>
        {/* {error && (
          <div className="text-red-500 px-5 text-center mt-2 text-sm">
            {error}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Nofile;
