import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const Modal = ({
  isOpen = false,
  setIsOpen = () => {},
  children,
  size = "xl4",
}) => {
  const modalSize = {
    xs: "sm:max-w-xs",
    sm: "sm:max-w-sm",
    md: "sm:max-w-md",
    lg: "sm:max-w-lg",
    xl: "sm:max-w-xl",
    xl2: "sm:max-w-2xl",
    xl3: "sm:max-w-3xl",
    xl4: "sm:max-w-4xl",
    xl5: "sm:max-w-5xl",
    xl6: "sm:max-w-6xl",
    xl7: "sm:max-w-7xl",
    xl8: "sm:max-w-8xl",
    xl9: "sm:max-w-9xl",
    full: "max-w-full",
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-60"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gradient-to-b from-slate-950/90 via-slate-950/40 to-slate-950/60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto backdrop-blur-[1px] bg-black/10">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={`w-full ${modalSize[size]} p-3 transform overflow-hidden rounded-2xl !bg-white text-left align-middle shadow-xl transition-all`}>
                <div className="w-full h-full">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
