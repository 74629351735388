import React from "react";
import { months } from "../../constant";

const CalenderLayout = ({
  children,
  currentYear = new Date().getFullYear(),
  selectedMonth = new Date().getMonth(),
  selectedYear = "",
  setCurrentYear = () => {},
  setSelectedYear = () => {},
  setSelectedMonth = () => {},
}) => {
  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };
  return (
    <div className="flex px-10 rounded-xl overflow-hidden">
      <div className="calendar w-full space-y-2">
        <div className="flex w-full gap-4">
          <div className="tabs relative px-0 space-y-2 flex-grow-0 min-w-[8rem]">
            <div className="controls flex items-center justify-center relative">
              <select
                value={selectedYear}
                onChange={handleYearChange}
                className="border-transparent focus:border-transparent focus:ring-transparent focus:ring-0 focus:outline-none bg-indigo-900 text-white w-full bg-none ring-0 rounded pl-3 pr-8 py-1 !appearance-none"
              >
                {[...Array(10).keys()].map((_, index) => (
                  <option key={index} value={currentYear - index}>
                    {currentYear - index}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-white">
                <i className="fa-solid fa-angle-down"></i>
              </div>
            </div>
            {months?.map((month, index) => (
              <div
                className={`relative px-6 py-1 cursor-pointer rounded text-center ${
                  selectedMonth === index
                    ? "active tab bg-indigo-900 text-white"
                    : "tab bg-indigo-100 text-indigo-500"
                }`}
                key={index}
                onClick={() => setSelectedMonth(index)}
              >
                {month}
              </div>
            ))}
          </div>
          <div className="w-full flex-grow overflow-x-hidden">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CalenderLayout;
