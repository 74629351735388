import React, { useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import DailyGoalSection from "../components/sections/DailyGoalSection";
import CalenderLayout from "../components/sections/CalenderLayout";
setTitle("Journal 365 | Daily Goal");

const DailyGoal = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(
    currentYear ? currentYear : ""
  );

  return (
    <>
      <CalenderLayout
        currentYear={currentYear}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        setCurrentYear={setCurrentYear}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}
      >
        <div className="p-5 bg-white rounded-md">
          <DailyGoalSection
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
          />
        </div>
      </CalenderLayout>
    </>
  );
};

export default DailyGoal;
