import React, { useState } from "react";
import DraggableFileupload from "../form/draggablefileUpload/DraggableFileupload";
import HideableTexteditor from "../elements/HideableTexteditor";
import SectionHeader from "./SectionHeader";
import RenderDaysbyMonth from "./common/RenderDaysbyMonth";

const DiarySection = ({
  selectedMonth = new Date().getMonth(),
  selectedYear = "",
}) => {
  const [activeButton, setActiveButton] = useState(
    new Date().getDate() || null
  );

  const getCurrentDate = (monthIndex, day) => {
    const year = selectedYear;
    const currentMonth = monthIndex;
    const currentDate = new Date(year, currentMonth, day).getDay();
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][currentDate];
  };

  return (
    <div className="w-full flex gap-3">
      <div className="w-32 min-w-[8rem] max-w-[8rem] bg-white p-2 rounded-md h-screen overflow-y-auto scrollbar">
        <div className="rounded-md overflow-hidden bg-indigo-900 sticky top-0 mb-1">
          <div className="h-full w-full px-1 py-2 space-y-0.5 group-odd:bg-indigo-100 group-even:bg-indigo-50 day">
            <div className={`date text-lg text-center font-bold text-white`}>
              {activeButton}
            </div>
            <div
              className={`day-label text-sm text-center text-white font-bold tracking-wider`}
            >
              {getCurrentDate(selectedMonth, activeButton)}
            </div>
          </div>
        </div>
        <RenderDaysbyMonth
          monthIndex={selectedMonth}
          getCurrentDate={getCurrentDate}
          setActiveButton={setActiveButton}
          activeButton={activeButton}
        />
      </div>

      <div className="w-full space-y-3 flex-grow bg-white h-fit p-6 rounded-md">
        <div className="w-full relative">
          <SectionHeader
            title={"Diary"}
            activeDate={activeButton}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
          />
          <HideableTexteditor />
        </div>
        <div className="w-full relative">
          <DraggableFileupload
            multiple={true}
            accept={["image/png", "image/jpeg"]}
            max={2}
          />
        </div>
      </div>
    </div>
  );
};

export default DiarySection;
