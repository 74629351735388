import React from "react";
import { NavLink } from "react-router-dom";

const TopHeaderMenuItem = ({
  menuLink,
  menuIcon,
  menuIconColor,
  menuLabel,
  isMenuOpen,
  highlight,
  ...props
}) => {
  return (
    <>
      <NavLink
        to={menuLink}
        className={({ isActive }) =>
          `navlink relative group flex items-center gap-1 overflow-hidden p-2 transition-all duration-200 ${
            isActive ? "active" : ""
          } ` +
          (highlight
            ? " !bg-[#dfe8f1] text-indigo-500 rounded font-semibold h-8 mt-auto mb-1 !px-2"
            : "!bg-transparent text-white")
        }
      >
        {({ isActive }) => <div className=" text-base">{menuLabel}</div>}
      </NavLink>
    </>
  );
};

export default TopHeaderMenuItem;
