import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import AuthLayout from "./layouts/AuthLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import Login from "./pages/Login";
import DailyGoal from "./pages/DailyGoal";

import MonthlyGoal from "./pages/MonthlyGoal";
import Diary from "./pages/Diary";
import Events from "./pages/Events";

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route path="/" exact element={<Login />} />
          </Route>
          <Route path="/dailyhighlights" element={<DefaultLayout />}>
            <Route path="/dailyhighlights" exact element={<DailyGoal />} />
          </Route>
          <Route path="/mymonth" element={<DefaultLayout />}>
            <Route path="/mymonth" exact element={<MonthlyGoal />} />
          </Route>
          <Route path="/diary" element={<DefaultLayout />}>
            <Route path="/diary" exact element={<Diary />} />
          </Route>
          {/* <Route path='/events' element={<DefaultLayout/>}>
            <Route path='/events' exact element={<Events />} />
          </Route> */}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
