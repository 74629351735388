import React from 'react';
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useRef, useState } from "react";
import { bottom } from "@popperjs/core";

import Image from '../components/elements/Image';
import { Link } from 'react-router-dom';

const UserDropdown = ({ xPlacement, ...props }) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const dropdownData = [
    // {_id:1, label: 'Profile', icon:'fa-circle-user', link:'/profile', standout:false},
    {_id:1, label: 'Logout', icon:'fa-arrow-right-from-arc', link:'/logout', standout:true},
  ]

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button ref={setTargetElement} className="flex items-center">
              <div className="relative w-full h-10 flex items-center justify-center focus:ring-0 transition-all duration-200">
                <div className="w-8 h-8 min-w-[32px] overflow-hidden rounded-full">
                  <Image src={'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'} alt={'Admin'} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover'}/>
                </div>
                <div className="text-sm font-semibold text-white mx-2">Admin</div>
                <div className="text-xs text-white">
                  <i className={"fa-light fa-fw " + (open?'fa-chevron-up':'fa-chevron-down')}></i>
                </div>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave="transition duration-75" leaveFrom="opacity-100" leaveTo="opacity-0" className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items className="w-40 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0">
                <div className="py-1 max-h-80 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar">
                  {dropdownData.map((item) => (
                    <Menu.Item key={item._id}>
                      <Link to={item.link} className={"flex items-center py-2 px-3 gap-3 text-slate-600 font-medium transition-all duration-200 " + 
                      (item.standout?"text-indigo-500 hover:bg-indigo-100":"text-slate-500 hover:bg-slate-100")}>
                        <div className="w-5 h-5 flex items-center justify-center text-base">
                          <i className={"fa-regular fa-fw " + (item.icon)}></i>
                        </div>
                        <div className="text-xs">{item.label}</div>
                      </Link>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default UserDropdown;